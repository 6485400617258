import React from "react";
import { faPoundSign } from "@fortawesome/free-solid-svg-icons/faPoundSign";
import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";

import AdminLayout from 'Pages/Admin/AdminLayout';

import { Loading } from "Components/Partials";
import { DateTimeFilter } from "Components/Filters";
import { PrimaryButton } from "Components/Button";
import FilterModal from "Components/Utilities/FilterModal";

import AdminTimesheetsApi from "Services/Api/Admin/HumanResources/Timesheets";
import {Modal, User} from "Services";

class TTS extends React.Component {
    /**
     * @var state
     * @type {{filters: {"end_date_time[afterDateTime]": Date, "start_date_time[beforeDateTime]": Date}}}
     */
    state = {
        loading: false,
        filters: {
            'end_date_time[afterDateTime]': new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth() - 1, User.data.active_team?.configs?.pay_period?.start ?? 26)), // Default to 26th of last month.
            'start_date_time[beforeDateTime]': new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), User.data.active_team?.configs?.pay_period?.end ?? 25)) // Default to 25th of current month.
        },
        records: [],
    };

    /**
     * @var filterColumns
     * @type {[{label: string, filters: [{component: function({onChange: Function, filters: Object, column: string, label: string, defaultMethod?: boolean, props: Object}): (undefined|*), column: string, label: string, props: {type: string, direction: string}},{component: function({onChange: Function, filters: Object, column: string, label: string, defaultMethod?: boolean, props: Object}): (undefined|*), column: string, label: string, props: {type: string, direction: string}}]}]}
     */
    filterColumns = [
        {
            label: 'Date',
            filters: [
                {
                    column: 'end_date_time',
                    label: 'After',
                    component: DateTimeFilter,
                    props: {
                        type: 'date',
                        direction: 'after'
                    }
                },
                {
                    column: 'start_date_time',
                    label: 'Before',
                    component: DateTimeFilter,
                    props: {
                        type: 'date',
                        direction: 'before'
                    }
                }
            ]
        }
    ];

    /**
     * @method componentDidMount
     * @return {Promise<void>}
     */
    componentDidMount = async () => {
        this.fetchData();
    };

    /**
     * @method componentDidUpdate
     * @param {object} prevState
     */
    componentDidUpdate(_, prevState) {
        if (!isEqual(prevState.filters, this.state.filters)) {
            this.fetchData();
        }
    }

    /**
     * @method fetchData
     * @return {Promise<void>}
     */
    fetchData = async () => {
        const { filters } = this.state;

        const fromDate = DateTime.fromJSDate(filters['end_date_time[afterDateTime]']).startOf('day');
        const toDate = DateTime.fromJSDate(filters['start_date_time[beforeDateTime]']);

        this.setState({ loading: true });

        const endDate = new Date(filters['start_date_time[beforeDateTime]']);
        endDate.setHours(23, 59, 59);

        var response = await AdminTimesheetsApi.get(null, {
            ...filters,
            'start_date_time[beforeDateTime]': endDate.toISOString(),
        });

        if (response.success) {
            const dataByUser = {};
            const records = [];

            response.data.data.filter(row => !!row.employee_id).forEach((row) => {
                if (!dataByUser[row.employee_id]) {
                    dataByUser[row.employee_id] = {
                        name: row.employee?.full_name ?? 'N/A',
                        average_hours_per_day: row.employee?.hr_settings?.hours_per_day ?? 0,
                        rows: [],
                    };
                }

                dataByUser[row.employee_id].rows.push(row);
            });

            Object.values(dataByUser).forEach((employee) => {
                const hoursData = [];
                const bankHolidayHours = { hours: 0, mins: 0 };

                [...employee.rows].sort((a, b) => a.hours.hours < b.hours.hours ? 1 : -1).forEach((r) => {
                    if (r.type === 'time_worked' && !r.is_bank_holiday) {
                        const s = DateTime.fromISO(r.start_date_time.iso_string).toSeconds();
                        const e = DateTime.fromISO(r.end_date_time.iso_string).toSeconds();

                        const c = hoursData.find((item) => {
                            return DateTime.fromISO(item.start_date_time.iso_string).toSeconds() < e &&
                                DateTime.fromISO(item.end_date_time.iso_string).toSeconds() > s
                        });

                        if (!c) {
                            hoursData.push(r);
                        }
                    }

                    if (r.is_bank_holiday) {
                        bankHolidayHours.hours += r.hours.hours;
                        bankHolidayHours.mins += r.hours.mins;
                    }
                });

                let hours = hoursData.reduce((hours, record) => {
                    if (!record.hours) {
                        return hours;
                    }

                    return {
                        hours: +hours.hours + +record.hours.hours,
                        mins: +hours.mins + +record.hours.mins,
                    };
                }, {
                    hours: 0,
                    mins: 0
                });

                if (hours.mins > 59) {
                    let hours_to_add = Math.floor(hours.mins / 60);

                    hours = {
                        hours: +hours.hours + +hours_to_add,
                        mins: +hours.mins - +(hours_to_add * 60)
                    };
                }

                let annualLeave = 0;

                employee.rows?.filter(row => row.type === 'holiday').forEach(row => {
                    row.days_breakdown?.forEach(day => {
                        const d = DateTime.fromSQL(day);

                        if (d >= fromDate && d <= toDate) {
                            annualLeave += Number(employee.average_hours_per_day);
                        }
                    });
                });

                records.push({
                    name: employee.name,
                    hours,
                    miles: employee.rows.reduce((mileage, record) => mileage + Number(record.mileage ?? 0), 0),
                    sleepIns: employee.rows.reduce((sleepsIn, record) => sleepsIn + Number(record.sleeps_in ?? 0), 0),
                    annualLeave: Number(annualLeave)?.toFixed(2),
                    bankHolidayHours,
                });
            });

            this.setState({
                apiData: response.data.data,
                records,
                loading: false,
            });
        }
    };

    /**
     * @method render
     * @return {JSX.Element}
     */
    /**
     * @method render
     * @return {JSX.Element}
     */
    render () {
        const { filters, loading, records } = this.state;

        return (
            <AdminLayout title="Pay Summary" titleIcon={faPoundSign}>
            <div className="px-4">
                {loading && (
                    <Loading />
                )}

                {!loading && (
                    <>
                        <div className="flex justify-end gap-2 mb-6">
                            <div className="flex flex-row gap-2">
                                <PrimaryButton
                                    onClick={() => {
                                        Modal.open({
                                            component: FilterModal,
                                            props: {
                                                columns: this.filterColumns,
                                                filters,
                                                handleFiltersCallback: (filters) => this.setState({filters})
                                            }
                                        });
                                    }}
                                    text="Filter"
                                />
                            </div>
                        </div>

                        <div className="bg-gray-200 rounded-lg my-6 p-2">
                            <table className="table-auto mx-auto">
                                <thead>
                                <tr className="bg-gray-300">
                                    <th className="px-4 py-2 border border-app-leading text-center">Name</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Hours</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Bank Holiday Hours</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Miles</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Sleep In</th>
                                    <th className="px-4 py-2 border border-app-leading text-center">Annual Leave</th>
                                </tr>
                                </thead>

                                <tbody>
                                { records?.map((record, key) => (
                                    <tr
                                        className="bg-white"
                                        key={ key }
                                    >
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            { record.name }
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            { record.hours.hours }h { record.hours.mins }m
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            { record.bankHolidayHours.hours }h { record.bankHolidayHours.mins }m
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            { Number ( record.miles ).toFixed ( 2 ) }
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            { record.sleepIns }
                                        </td>
                                        <td className="px-4 py-2 border border-app-leading text-center">
                                            { record.annualLeave }h
                                        </td>
                                    </tr>
                                ) ) }

                                { this.renderTotals () }
                                </tbody>
                            </table>
                        </div>
                    </>
                ) }
            </div>
        </AdminLayout>
        );
    }

    /**
     * @method renderTotals
     * @return {JSX.Element|null}
     */
    /**
     * @method renderTotals
     * @return {JSX.Element|null}
     */
    renderTotals = () => {
        const { records } = this.state;

        // Calculate total hours including bank holiday hours
        let totalHours = records.reduce((hours, record) => {
            if (!record.hours) {
                return hours;
            }

            const bankHolidayHours = record.bankHolidayHours || { hours: 0, mins: 0 };

            return {
                hours: +hours.hours + +record.hours.hours,
                mins: +hours.mins + +record.hours.mins,
            };
        }, {
            hours: 0,
            mins: 0
        });

        // Normalize minutes to hours
        if (totalHours.mins > 59) {
            let hoursToAdd = Math.floor(totalHours.mins / 60);
            totalHours.hours += hoursToAdd;
            totalHours.mins -= hoursToAdd * 60;
        }

        // Calculate additional rate hours (bank holiday hours)
        let additionalRateHours = records.reduce((hours, record) => {
            if (!record.bankHolidayHours) {
                return hours;
            }

            return {
                hours: +hours.hours + +record.bankHolidayHours.hours,
                mins: +hours.mins + +record.bankHolidayHours.mins,
            };
        }, {
            hours: 0,
            mins: 0
        });

        // Normalize additional rate minutes to hours
        if (additionalRateHours.mins > 59) {
            let hoursToAdd = Math.floor(additionalRateHours.mins / 60);
            additionalRateHours.hours += hoursToAdd;
            additionalRateHours.mins -= hoursToAdd * 60;
        }

        return (
            <tr>
                <td></td>
                <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                    { totalHours.hours }h { totalHours.mins }m
                </td>
                <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                    { additionalRateHours.hours }h { additionalRateHours.mins }m
                </td>
                <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                    { records.reduce ( ( miles, record ) => miles + Number ( record.miles ?? 0 ), 0 )?.toFixed ( 2 ) }
                </td>
                <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                    { records.reduce ( ( sleepIns, record ) => sleepIns + Number ( record.sleepIns ?? 0 ), 0 ) }
                </td>
                <td className="bg-gray-300 px-4 py-2 border border-app-leading text-center">
                    { records.reduce ( ( annualLeave, record ) => annualLeave + Number ( record.annualLeave ?? 0 ), 0 )?.toFixed ( 2 ) }
                </td>
            </tr>
        );
    };
}

export default TTS;
